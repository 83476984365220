import { observer } from "mobx-react";
import { BottomFixed, Nav } from "ms-ui";
import { useURI } from "ms_router";
import { Logo } from "src/pages/Main/views/MainSection/logo";
import { TitleTpl } from "./TitleTpl";
import { StContainer } from "../Components/Style";
import { InviteSubTpl } from "./InviteSubTpl";
import { InviteBtnsTpl } from "./InviteBtnsTpl";
import { useEffect, useState } from "react";
import { get_ref_cd } from "src/repository/Member/myinfo";
import { MBus } from "../Components/MBus";

export interface IInfo {
  nick: string;
  refCd: string;
}

const InviteRoute = observer(() => {
  const { navigate, getQuery } = useURI();
  const q = getQuery<{ rcode?: string; refCd?: string; type?: string }>();
  const [info, setInfo] = useState<IInfo>();

  const loadNick = async () => {
    try {
      const refCd = q.refCd ?? q.rcode;
      if (!refCd) return;
      const res = await get_ref_cd({ refCd }).req();
      if (res.data) {
        setInfo(res.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  useEffect(() => {
    loadNick();
  }, []);

  return (
    <>
      <Nav
        left={{
          type: "custom",
          onClick: () => navigate("/"),
          svg: <Logo />,
        }}
      />

      <StContainer>
        <TitleTpl info={info!} />
        <InviteSubTpl info={info!} />
        <InviteBtnsTpl info={info!} />

        <BottomFixed>
          <MBus />
        </BottomFixed>
      </StContainer>
    </>
  );
});

export default InviteRoute;
