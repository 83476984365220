/**
  * @version: 1.0.0
  * @createdAt: 2024-07-12 12:55:02
  * @createdBy: gimsoyeon-ui-Macmini.local
*/

import { ResGetInfoMe  } from './interface/res/get/Res.Get.InfoMe';
import { ResGetInfoRefCd  } from './interface/res/get/Res.Get.InfoRefCd';
import { ResGetInfoSlist  } from './interface/res/get/Res.Get.InfoSlist';

import { GET, POST, PUT, DELETE  } from "ms-ui";
class Myinfo {

/**
 * 마이페이지에서 내 정보 조회
*/
get_my_info(){
 return GET<ResGetInfoMe>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/myinfo`,
 });
}
/**
 * 비로그인 상태로 추천인코드로 닉네임 조회, 초대장 페이지에서 사용중
*/
get_ref_cd(data: {   refCd: string }){
 return GET<ResGetInfoRefCd>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/myinfo/refcd`,
 }, data);
}
/**
 * 마이페이지에서, 연결된 sns 리스트를 조회
*/
get_slist(){
 return GET<ResGetInfoSlist>({
   ckName: "USER",
   baseURL: `${window.__MSHUTTLE__PATH__.member_resource}`,
   url: `/myinfo/slist`,
 });
}
 
}

export const MyinfoRepository = new Myinfo();
export const { get_my_info, get_ref_cd, get_slist } = MyinfoRepository;
	