import React from "react";
import { observer } from "mobx-react";
import { spacing, contents, Body1 } from "ms-ui";
import styled from "styled-components";

export interface IInviteBtns {
  rtBtn: IButton;
  searchBtn: IButton;
}

export interface IButton {
  onClick: () => void;
  children?: React.ReactNode;
}

export const InviteBtns = observer(({ rtBtn, searchBtn }: IInviteBtns) => {
  return (
    <>
      <StDiv1>
        <StDiv2 onClick={rtBtn.onClick}>
          <img
            src={`https://s3.ap-northeast-2.amazonaws.com/ms-file/${
              process.env.REACT_APP_STAGE === "production" ? "prod" : "dev"
            }/image/icon/location.svg`}
            width={`fit-content`}
            height={`fit-content`}
          />
          <StDiv3>{rtBtn.children}</StDiv3>
        </StDiv2>
        <StDiv4 onClick={searchBtn.onClick}>
          <img
            src={`https://s3.ap-northeast-2.amazonaws.com/ms-file/${
              process.env.REACT_APP_STAGE === "production" ? "prod" : "dev"
            }/image/icon/search.svg`}
            width={`fit-content`}
            height={`fit-content`}
          />
          <StDiv5>내 출근길 찾기</StDiv5>
        </StDiv4>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding: ${spacing[32].value} ${spacing[16].value};
  gap: ${spacing[12].value};
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  height: 180px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing[12].value};
  flex: 1 0 0;
  border-radius: 5px;
  background: ${contents.secondary.value};
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  width: 100%;
`;

const StDiv3 = styled.div`
  ${Body1};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${spacing[12].value};
  flex: 1 0 0;
  border-radius: 5px;
  background: ${contents.secondary.value};
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  width: 100%;
`;

const StDiv5 = styled.div`
  ${Body1};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;
