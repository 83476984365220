import { observer } from "mobx-react";
import { isNil } from "ms_data";
import { useURI } from "ms_router";
import { InviteLoginMakeTitle } from "../../Components/InviteLoginMakeTitle";
import { InviteNonLoginMakeTitle } from "../../Components/InviteNonLoginMakeTitle";
import { InviteLoginTitle } from "../../Components/InviteLoginTitle";
import { InviteNonLoginTitle } from "../../Components/InviteNonLoginTitle";
import { IInfo } from "..";
import { spacing } from "ms-ui";

export const TitleTpl = observer((props: { info: IInfo }) => {
  const { info } = props;
  const { getQuery } = useURI();
  const q = getQuery<{ type: string }>();

  return (
    <div style={{ paddingTop: spacing[64].value }}>
      {q.type === "e" ? (
        <>
          {info && !isNil(info.nick) ? (
            <InviteLoginMakeTitle userNm={info.nick} />
          ) : (
            <InviteNonLoginMakeTitle />
          )}
        </>
      ) : (
        <>
          {info && !isNil(info.nick) ? (
            <InviteLoginTitle userNm={info.nick} />
          ) : (
            <InviteNonLoginTitle />
          )}
        </>
      )}
    </div>
  );
});
