import { observer } from "mobx-react";
import { useURI } from "ms_router";
import { InviteSub } from "./InviteSub";
import { isNil } from "ms_data";
import { spacing } from "ms-ui";
import { IInfo } from "..";

export const InviteSubTpl = observer((props: { info: IInfo }) => {
  const { info } = props;
  const { getQuery } = useURI();
  const q = getQuery<{ rcode?: string; refCd?: string; type?: string }>();

  return (
    <div style={{ padding: `${spacing[16].value} 0` }}>
      <InviteSub
        content={
          q.type === "e" ? (
            info && !isNil(info.nick) ? (
              <>
                {info.nick}님이 새로운 출근길을 요청했어요!
                <br />
                출근길이 빨리 오픈 될 수 있도록
                <br />
                함께 요청해 주세요!
              </>
            ) : (
              <>
                모두의셔틀이 동네별
                <br />
                새로운 출근길 요청을 받고 있어요!
              </>
            )
          ) : (
            <>
              모두의셔틀과 함께
              <br />
              꿀잠 출근 라이프를 시작하세요.
            </>
          )
        }
      />
    </div>
  );
});
