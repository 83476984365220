import React from "react";
import { observer } from "mobx-react";
import { contents, foundation, Header1 } from "ms-ui";
import styled from "styled-components";

export interface IInviteLoginMakeTitle {
  userNm: string;
}

export const InviteLoginMakeTitle = observer(
  ({ userNm }: IInviteLoginMakeTitle) => {
    return (
      <>
        <StDiv1>
          <StDiv2>
            <StDiv3>
              <StDiv4>{userNm}</StDiv4>
              <StDiv5>님의</StDiv5>
            </StDiv3>
            <StDiv6>출근길을 오픈시켜주세요!</StDiv6>
          </StDiv2>
        </StDiv1>
      </>
    );
  }
);

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
`;

const StDiv3 = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StDiv4 = styled.div`
  ${Header1};
  color: ${foundation.normal.secondary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv5 = styled.div`
  ${Header1};
  color: ${contents.primary.value};
  text-align: center;
  width: fit-content;
`;

const StDiv6 = styled.div`
  ${Header1};
  align-self: stretch;
  color: ${contents.primary.value};
  text-align: center;
`;
