import React from "react";
import { observer } from "mobx-react";
import { spacing, contents } from "ms-ui";
import styled from "styled-components";

export const MBus = observer(() => {
  return (
    <>
      <StDiv1>
        <img
          src={`https://s3.ap-northeast-2.amazonaws.com/ms-file/${
            process.env.REACT_APP_STAGE === "production" ? "prod" : "dev"
          }/image/background/Shuttle.svg`}
          width={`fit-content`}
          height={`fit-content`}
        />
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${spacing[16].value};
  justify-content: center;
  background: ${contents.secondary.value};
`;
