import React from "react";
import { observer } from "mobx-react";
import { contents, Body2 } from "ms-ui";
import styled from "styled-components";

export interface IInviteSub {
  content: React.ReactNode;
}

export const InviteSub = observer(({ content }: IInviteSub) => {
  return (
    <>
      <StDiv1>
        <StDiv2>{content}</StDiv2>
      </StDiv1>
    </>
  );
});

const StDiv1 = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: ${contents.secondary.value};
`;

const StDiv2 = styled.div`
  ${Body2};
  color: ${contents.subtitle.value};
  text-align: center;
  width: fit-content;
`;
