import { observer } from "mobx-react";
import { InviteBtns } from "./InviteBtns";
import { useURI } from "ms_router";
import { IInfo } from "..";
import { isNil } from "ms_data";
import { MSHUTTLE_PATH } from "src/constants/addr";

export const InviteBtnsTpl = observer((props: { info: IInfo }) => {
  const { info } = props;
  const { navigate, getQuery } = useURI();
  const q = getQuery<{
    rcode: string;
    refCd: string;
    type: string;
    rid: string;
    rtId: string;
    mno: string;
    makeId: string;
    utm_campaign: string;
  }>();

  return (
    <InviteBtns
      rtBtn={{
        onClick: () => {
          //알림받기
          if (!isNil(q.mno) || !isNil(q.makeId)) {
            let makeId = q.mno || q.makeId;
            if (makeId) {
              window.location.href = `${MSHUTTLE_PATH.make}/share?makeId=${makeId}`;
            }
            //경로 상세
          } else if (!isNil(q.rid) || !isNil(q.rtId)) {
            let pathName = q.utm_campaign ?? "running";
            let rtId = q.rid || q.rtId;
            if (rtId) {
              window.location.href = `${MSHUTTLE_PATH.route}/${pathName}?rtId=${rtId}`;
            }
          } else {
            navigate("/search");
          }
        },
        children: (
          <>
            {q.type === "e" ? (
              info?.nick ? (
                <>
                  {info.nick}님이
                  <br />
                  요청한 출근길 보기
                </>
              ) : (
                <>출근길 요청하러 가기</>
              )
            ) : info?.nick ? (
              <>
                {info.nick}님의
                <br />
                출근길 보기
              </>
            ) : (
              <>
                공유한
                <br />
                출근길 보기
              </>
            )}
          </>
        ),
      }}
      searchBtn={{
        onClick: () => navigate(`/search/start_form`),
      }}
    />
  );
});
